import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const testFlagIsEnabled = useIsFeatureEnabled('testFlag')
  const isLeavePagePromptEnabled = useIsFeatureEnabled(
    'Matchning-Kundforetag-Frontend_leavePagePrompt_perm_240701'
  )
  const isHotjarEnabled = useIsFeatureEnabled(
    'Matchning-Kundforetag-Frontend_hotjar_perm_240701'
  )
  const isGtmTrackingEnabled = useIsFeatureEnabled(
    'gtm.enabled.matchning-kundforetag'
  )
  const isMultipleLocationsEnabled = useIsFeatureEnabled(
    'Matchning-Kundforetag-Frontend_multipleLocations_perm_240701'
  )

  return {
    testFlagIsEnabled,
    isGtmTrackingEnabled,
    isLeavePagePromptEnabled,
    isMultipleLocationsEnabled,
    isHotjarEnabled,
  }
}
