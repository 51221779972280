import React from 'react'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { useContent } from '@local/src/Utils/Content/useContent'
import { TextField } from '@mui/material'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'

export const EmployingCompanyNameField: React.FC = () => {
  const { label, description, placeholder } =
    useContent().form.employingCompanyName
  const { values, handleChange, errors, handleBlur } = useRecruitmentForm()

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('employingCompanyName')(event.target.value, {
      validationType: 'onError',
    })
  }
  return (
    <LabelWrapper description={description}>
      <ResponsiveWrapper xs={12} sm={8}>
        <TextField
          fullWidth
          sx={{ maxWidth: (t) => t.breakpoints.values.sm }}
          label={label}
          placeholder={placeholder}
          value={values.employingCompanyName}
          onChange={handleCompanyNameChange}
          helperText={errors.employingCompanyName}
          error={errors.employingCompanyName !== undefined}
          onBlur={handleBlur('employingCompanyName')}
        />
      </ResponsiveWrapper>
    </LabelWrapper>
  )
}
