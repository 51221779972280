import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import TextEditor from '@local/src/Components/TextEditor/TextEditor'
import { useContent } from '@local/src/Utils/Content/useContent'
import React from 'react'
import { FormattedText } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export const EmploymentDescriptionField: React.FC = () => {
  const { label, placeholder } = useContent().form.employmentDescription

  const { values, handleChange, errors, handleBlur } = useRecruitmentForm()

  const handleInputChange = (value: FormattedText) => {
    handleChange('employmentDescription')(value, {
      validationType: 'onError',
    })
  }

  return (
    <LabelWrapper
      description={label}
      htmlFor="employment-description"
      gutterBottom={false}
    >
      <TextEditor
        content={values.employmentDescription?.html}
        onChange={handleInputChange}
        editorId="employment-description"
        error={!!errors.employmentDescription?.text}
        helperText={errors.employmentDescription?.text}
        placeholder={placeholder}
        onBlur={handleBlur('employmentDescription')}
      />
    </LabelWrapper>
  )
}
