import { EmploymentType, WorkingHours } from '@local/src/Api/types'
import {
  LocationInformation,
  LocationType,
  RecruitmentForm,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { array, boolean, mixed, object, string } from 'yup'

export const useRecruitmentFormValidationSchema = (
  multipleLocations = false
) => {
  const {
    maxChars,
    maxItems,
    minChars,
    minItems,
    requiredField,
    requiredNamedField,
    requiredJobHeadingField,
    requiredFieldEmploymentDescription,
  } = useContent().form.common

  return object<RecruitmentForm>()
    .shape({
      jobHeading: string()
        .required(requiredJobHeadingField)
        .min(3, replaceEpiVariables(minChars, { count: '3' }))
        .max(100, replaceEpiVariables(maxChars, { count: '100' })),
      locations: array()
        .of(
          object().shape({
            id: string().required(),
            name: string().required(),
            type: mixed<LocationType>().required(),
            locatedIn: mixed<LocationInformation>(),
          })
        )
        .min(
          1,
          multipleLocations
            ? replaceEpiVariables(minItems, { count: 'en', items: 'ort' })
            : replaceEpiVariables(requiredNamedField, { name: 'en ort' })
        )
        .required(),
      jobTitle: object({
        id: string().optional().nullable(),
        name: string()
          .max(100, replaceEpiVariables(maxChars, { count: (100).toString() }))
          .required(
            replaceEpiVariables(requiredNamedField, { name: 'en yrkestitel' })
          ),
      }),
      employmentDescription: object({
        text: string()
          .required(requiredFieldEmploymentDescription)
          .max(15000, replaceEpiVariables(maxChars, { count: '15 0000' }))
          .min(10, replaceEpiVariables(minChars, { count: '10' })),
      }),
      companyDescription: object({
        text: string()
          .nullable()
          .transform((v, o) => (o ? v : null))
          .max(15000, replaceEpiVariables(maxChars, { count: '15 0000' }))
          .min(10, replaceEpiVariables(minChars, { count: '10' })),
      }),
      companyName: string()
        .required(
          replaceEpiVariables(requiredNamedField, { name: 'företagsnamn' })
        )
        .max(50, replaceEpiVariables(maxChars, { count: '50' })),
      isRecruitingForDeploymentAgency: boolean(),
      employingCompanyType: string().required(requiredField),
      employingCompanyName: string().when('employingCompanyType', {
        is: (val: string) => val === 'another-company',
        then: (schema) =>
          schema.required(
            replaceEpiVariables(requiredNamedField, { name: 'företagsnamn' })
          ),
        otherwise: (schema) => schema.notRequired(),
      }),
      showEmployingCompanyNameInJobAd: boolean(),
      skills: array()
        .of(
          object().shape({
            id: string().nullable(),
            name: string().required(),
          })
        )
        .min(
          1,
          replaceEpiVariables(minItems, { count: 'en', items: 'kunskap' })
        )
        .max(
          10,
          replaceEpiVariables(maxItems, {
            count: '10',
            items: 'kunskaper',
          })
        ),
      workingHours: mixed<WorkingHours>().required(
        replaceEpiVariables(requiredNamedField, { name: 'omfattning' })
      ),
      employmentType: mixed<EmploymentType>().required(
        replaceEpiVariables(requiredNamedField, { name: 'anställningsform' })
      ),
    })
    .required()
}
