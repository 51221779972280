import React from 'react'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { useContent } from '@local/src/Utils/Content/useContent'
import { TextField } from '@mui/material'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'

export const CompanyNameField: React.FC = () => {
  const { label, description, placeholder } = useContent().form.companyName

  const { values, handleChange, handleBlur, errors } = useRecruitmentForm()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('companyName')(event.target.value, {
      validationType: 'onError',
    })
  }

  return (
    <LabelWrapper description={description}>
      <ResponsiveWrapper xs={12} sm={8}>
        <TextField
          fullWidth
          sx={{ maxWidth: (t) => t.breakpoints.values.sm }}
          value={values?.companyName}
          label={label}
          placeholder={placeholder}
          onChange={handleInputChange}
          onBlur={handleBlur('companyName')}
          error={!!errors.companyName}
          helperText={errors.companyName}
        />
      </ResponsiveWrapper>
    </LabelWrapper>
  )
}
