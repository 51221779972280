import React from 'react'
import {
  CompanyDescriptionField,
  EmploymentDescriptionField,
  JobTitleField,
  JobHeadingField,
  SkillsField,
  LocationFieldSingle,
  LocationFieldMultiple,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields'
import { Stack } from '@mui/material'
import { Formik } from 'formik'
import { useRecruitmentFormValidationSchema } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/validationSchema'
import { CompanyOwnerRadio } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/CompanyOwnerRadio/CompanyOwnerRadio'
import {
  EmployingCompanyType,
  mapRecruitmentResponseToForm,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { WizardActions } from '@local/src/Components/Wizard/WizardActions'
import { CompanyNameField } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/CompanyNameField/CompanyNameField'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { ShowCompanyNameCheckbox } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/ShowCompanyNameCheckbox/ShowCompanyNameCheckbox'
import { EmployingCompanyNameField } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/EmployingCompanyNameField/EmployingCompanyNameField'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from '@local/src/Utils/Hooks/useFeatureFlag'
import { RecruitmentResponse } from '@local/src/Api/types'
import { pushPathWithoutBlock } from '@local/src/Utils/Hooks/usePageLeave'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { getTabSearchLocation } from '@local/src/Utils/urlHelpers'
import { CreatedRecruitmentsTabs } from '@local/src/Pages/StartPage/CreatedRecruitments/CreatedRecruitments'
import { WorkingHoursField } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/WorkingHoursField/WorkingHoursField'
import { EmploymentTypeField } from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/EmploymentTypeField/EmploymentTypeField'

interface RecruitmentFormViewProps {
  recruitment: RecruitmentResponse
}

const RecruitmentFormView: React.FC<RecruitmentFormViewProps> = ({
  recruitment,
}) => {
  const { isMultipleLocationsEnabled } = useFeatureFlag()
  const validationSchema = useRecruitmentFormValidationSchema(
    isMultipleLocationsEnabled
  )

  const {
    formSections: {
      companyInformationLabel,
      recruitmentInformationLabel,
      employmentInformationLabel,
    },
    recruitmentFormSteps: {
      nextLabel,
      cancelLabel,
      confirmCancelDialogTitle,
      confirmCancelDialogMessage,
      confirmCancelDialogConfirmLabel,
      confirmCancelDialogCancelLabel,
      startPagePath,
    },
  } = useContent().form

  const history = useHistory()

  const {
    isPending: isPendingConfirmation,
    promise: confirm,
    resolve: resolveConfirmation,
  } = useResolve<boolean>()

  const handleCancelClicked = () => {
    void (async () => {
      const confirmed = await confirm()
      if (confirmed) {
        pushPathWithoutBlock(
          history,
          getTabSearchLocation(CreatedRecruitmentsTabs.Drafts, startPagePath)
        )
      }
    })()
  }

  const scrollToError = () => {
    const errorElement = document.querySelector('.Mui-error')
    errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <>
      <ConfirmationDialog
        title={confirmCancelDialogTitle}
        message={confirmCancelDialogMessage}
        confirmLabel={confirmCancelDialogConfirmLabel}
        cancelLabel={confirmCancelDialogCancelLabel}
        isOpen={isPendingConfirmation}
        onConfirm={() => resolveConfirmation(true)}
        onClose={() => resolveConfirmation(false)}
      />
      <Formik
        initialValues={mapRecruitmentResponseToForm(recruitment)}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {({ validateForm, values }) => (
          <Stack spacing={5} justifyContent="stretch">
            <CompanyOwnerRadio />
            <LabelWrapper label={recruitmentInformationLabel}>
              <JobHeadingField />
              <JobTitleField />
              {isMultipleLocationsEnabled ? (
                <LocationFieldMultiple />
              ) : (
                <LocationFieldSingle />
              )}
              <EmploymentDescriptionField />
            </LabelWrapper>
            <SkillsField />
            <LabelWrapper label={employmentInformationLabel} gutterBottom>
              <Stack
                spacing={3}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="stretch"
              >
                <EmploymentTypeField />
                <WorkingHoursField />
              </Stack>
            </LabelWrapper>
            <LabelWrapper label={companyInformationLabel}>
              <CompanyNameField />
              <CompanyDescriptionField />
              {values.employingCompanyType ===
                EmployingCompanyType.AnotherCompany && (
                <>
                  <EmployingCompanyNameField />
                  <ShowCompanyNameCheckbox />
                </>
              )}
            </LabelWrapper>
            <WizardActions
              primaryButtonText={nextLabel}
              secondaryButtonText={cancelLabel}
              onPrimaryButtonClicked={({ nextStep }) => {
                void validateForm().then((errors) => {
                  Object.keys(errors).length === 0
                    ? nextStep()
                    : scrollToError()
                })
              }}
              onSecondaryButtonClicked={handleCancelClicked}
            />
          </Stack>
        )}
      </Formik>
    </>
  )
}

export default RecruitmentFormView
