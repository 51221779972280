import React from 'react'
import { useGetLocationsQuery } from '@local/src/Api/locationsApi'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  TextField,
} from '@mui/material'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Search } from '@mui/icons-material'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { LocationInformation } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { BottomPopper } from '@local/src/Components/BottomPopper'
import parse from 'html-react-parser'

export const LocationFieldSingle: React.FC = () => {
  const { label, placeholder, description } = useContent().form.jobLocation
  const [showOptions, setShowOptions] = React.useState(false)
  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()
  const [input, setInput] = React.useState('')

  const setLocation = handleChange('locations')

  const shouldSearch = input.length > 2
  const { data: locations } = useGetLocationsQuery(
    { search: input, type: ['City'] },
    {
      skip: !shouldSearch,
    }
  )

  const handleInputChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setShowOptions(reason !== 'clear')
    setInput(value)
  }

  const handleOnClose = () => {
    setShowOptions(false)
  }

  const handleAutocompleteChange = (
    _event: unknown,
    newValue: LocationInformation
  ) => {
    setLocation(newValue ? [newValue] : [])
  }

  const handleOptionLabel = (option: LocationInformation) => option.name
  const handleRenderOptionLabel = (
    props: React.HTMLAttributes<HTMLLIElement>,
    location: LocationInformation
  ) => (
    <li {...props} key={location.id} translate="no">
      {parse(location.nameWithHighLightedSearchTerm)}
    </li>
  )

  const value = values?.locations[0] ?? null

  return (
    <LabelWrapper description={description}>
      <ResponsiveWrapper xs={12} sm={8}>
        <Autocomplete
          popupIcon={<Search />}
          forcePopupIcon
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          inputValue={input}
          autoHighlight
          autoSelect
          onChange={handleAutocompleteChange}
          onInputChange={handleInputChange}
          options={showOptions && locations ? locations : []}
          onClose={handleOnClose}
          loadingText="Hämtar platser..."
          value={value}
          getOptionLabel={handleOptionLabel}
          PopperComponent={BottomPopper}
          renderOption={handleRenderOptionLabel}
          data-testid="location-autocomplete"
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
              }}
              helperText={errors?.locations as string}
              error={Boolean(errors?.locations)}
              onBlur={handleBlur('locations')}
            />
          )}
          sx={{
            maxWidth: (t) => t.breakpoints.values.sm,
            '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
          }}
        />
      </ResponsiveWrapper>
    </LabelWrapper>
  )
}
