import {
  CompanyType,
  EmployingCompanyType,
  FormattedText,
  LocationInformation,
  RecruitmentForm,
  Skill,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { usePatchRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import {
  EmploymentType,
  JobTitle,
  PatchRecruitmentPaths,
  PathchOps,
  WorkingHours,
} from '@local/src/Api/types'
import { useFormikContext } from 'formik'

export const usePatchRecruitment = () => {
  const [patch, { isError, isLoading }] = usePatchRecruitmentMutation()

  const {
    values: { id },
  } = useFormikContext<RecruitmentForm>()

  const patchHeading = (value: string) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.heading,
          value,
        },
      ],
    })

  const patchJobTitle = (value: JobTitle) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.jobtitle,
          value,
        },
      ],
    })

  const patchEmploymentDescription = (value: FormattedText) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.employmentDescription,
          value: value.text,
        },
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.employmentDescriptionFormatted,
          value: value.html,
        },
      ],
    })

  const patchLocations = (value: LocationInformation[]) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.location,
          value: value.map((location) => ({
            id: location.id,
            name: location.name,
            locationType: location.type,
          })),
        },
      ],
    })

  const patchSkills = (value: Skill[]) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.skills,
          value: value,
        },
      ],
    })

  type RecruitingForCompanyArgs = {
    type: EmployingCompanyType
    name?: string
    showName: boolean
  }

  const mapRecruitingForCompanyArgs = (
    args: RecruitingForCompanyArgs
  ): {
    name?: string
    showName: boolean
  } | null =>
    args.type === EmployingCompanyType.AnotherCompany
      ? { name: args.name, showName: args.showName }
      : null

  const patchRecruitingForCompany = (value: RecruitingForCompanyArgs) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.recruitingForCompany,
          value: mapRecruitingForCompanyArgs(value),
        },
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.companyType,
          value: CompanyType.EmployingCompany,
        },
      ],
    })

  const patchCompanyName = (value: string) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.companyName,
          value,
        },
      ],
    })

  const patchCompanyType = (value: CompanyType) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.companyType,
          value,
        },
      ],
    })

  const patchCompanyDescription = (value: FormattedText) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.companyDescription,
          value: value.text,
        },
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.companyDescriptionFormatted,
          value: value.html,
        },
      ],
    })

  const patchPublicationEndDate = (value?: Date) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.publicationEndDate,
          value:
            value && !isNaN(new Date(value).getTime())
              ? value.toISOString()
              : null,
        },
      ],
    })

  const patchEmploymentType = (value: EmploymentType) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.employmentType,
          value,
        },
      ],
    })

  const patchWorkingHours = (value: WorkingHours) =>
    patch({
      id,
      patches: [
        {
          op: PathchOps.replace,
          path: PatchRecruitmentPaths.workingHours,
          value: [value],
        },
      ],
    })

  return {
    patchHeading,
    patchJobTitle,
    patchEmploymentDescription,
    patchLocations,
    patchSkills,
    patchRecruitingForCompany,
    patchCompanyDescription,
    patchCompanyName,
    patchCompanyType,
    patchPublicationEndDate,
    patchEmploymentType,
    patchWorkingHours,
    isError,
    isLoading,
  }
}
