import React, { useCallback, useEffect, useState } from 'react'
import RecruitmentFormView from '@local/src/Pages/DraftPage/RecruitmentFormView/RecruitmentFormView'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { Wizard } from '@local/src/Components/Wizard/Wizard'
import { WizardContent } from '@local/src/Components/Wizard/WizardContent'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useHistory } from 'react-router-dom'
import PublicationFormView from '@local/src/Pages/DraftPage/PublicationFormView/PublicationFormView'
import PublishView from '@local/src/Pages/DraftPage/PublishView/PublishView'
import { WizardTracker } from '@local/src/Components/Wizard/WizardTracker'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { usePageLeave } from '@local/src/Utils/Hooks/usePageLeave'
import { useFeatureFlag } from '@local/src/Utils/Hooks/useFeatureFlag'
import { PublishingStatus } from '@local/src/Api/types'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { useHotjar } from '@local/src/Utils/Hooks/useHotjar'

const DraftPage = () => {
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true)
  const [hasSuccessfullyPublished, setHasSuccessfullyPublished] =
    useState(false)
  const recruitmentId = useCurrentRecruitmentId()
  const {
    errorMessage,
    publish: { onPublishUrl },
    pageLeavePrompt: {
      title: pageLeaveHeading,
      body: pageLeaveMessage,
      confirmButtonLabel: pageLeaveConfirmLabel,
      cancelButtonLabel: pageLeaveCancelLabel,
    },
  } = useContent()
  const { isLeavePagePromptEnabled } = useFeatureFlag()
  const history = useHistory()
  const sendHotjarEvent = useHotjar()

  const { isAwaitingConfirmation, confirmPageLeave, cancelPageLeave } =
    usePageLeave(isLeavePagePromptEnabled && shouldBlockNavigation)

  const handleFormFinished = useCallback(() => {
    setHasSuccessfullyPublished(true)
    setShouldBlockNavigation(false)
    sendHotjarEvent('kf_annonserat')
  }, [sendHotjarEvent])

  useEffect(() => {
    hasSuccessfullyPublished &&
      !shouldBlockNavigation &&
      history.push(onPublishUrl)
  }, [hasSuccessfullyPublished, shouldBlockNavigation, history, onPublishUrl])

  const {
    data: recruitment,
    isFetching,
    isError,
    refetch,
  } = useCurrentRecruitment()

  const handleStepChange = () => {
    void refetch()
  }

  return (
    <>
      <ResponsiveWrapper xs={12} md={8}>
        <Wizard
          labels={['Om tjänsten', 'Annonseringstid', 'Publicera']}
          onStepChange={handleStepChange}
          isLoading={isFetching}
          isError={
            !recruitmentId ||
            isError ||
            !recruitment ||
            recruitment.publication.status !== PublishingStatus.Draft
          }
          errorComponent={
            <EmptyResult
              heading={errorMessage.heading}
              description={errorMessage.description}
              action={{
                label: errorMessage.actionLabel,
                link: errorMessage.startPagePath,
              }}
            />
          }
        >
          <WizardTracker>
            <WizardContent step={0}>
              <RecruitmentFormView recruitment={recruitment} />
            </WizardContent>
            <WizardContent step={1}>
              <PublicationFormView recruitment={recruitment} />
            </WizardContent>
            <WizardContent step={2}>
              <PublishView
                recruitment={recruitment}
                onPublishSucceeded={handleFormFinished}
              />
            </WizardContent>
          </WizardTracker>
        </Wizard>
      </ResponsiveWrapper>
      <ConfirmationDialog
        isOpen={isAwaitingConfirmation}
        title={pageLeaveHeading}
        message={pageLeaveMessage}
        confirmLabel={pageLeaveConfirmLabel}
        cancelLabel={pageLeaveCancelLabel}
        onConfirm={confirmPageLeave}
        onClose={cancelPageLeave}
      />
    </>
  )
}

export default DraftPage
