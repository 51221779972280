import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useContent } from '@local/src/Utils/Content/useContent'
import { TextField } from '@mui/material'
import React from 'react'

export const JobHeadingField: React.FC = () => {
  const { label, description, placeholder } = useContent().form.heading

  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('jobHeading')(event.target.value, {
      validationType: 'onError',
    })
  }

  return (
    <LabelWrapper description={description}>
      <TextField
        fullWidth
        value={values?.jobHeading}
        label={label}
        placeholder={placeholder}
        onChange={handleInputChange}
        error={!!errors.jobHeading}
        helperText={errors.jobHeading ?? 'Max 100 tecken'}
        onBlur={handleBlur('jobHeading')}
      />
    </LabelWrapper>
  )
}
