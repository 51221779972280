import React from 'react'
import { ApplicantListIcon } from '@local/src/Components/ApplicantListIcon/ApplicantListIcon'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CheckCircleOutline, DoNotDisturbOnOutlined } from '@mui/icons-material'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

interface SkillsChecklistProps {
  applicationSkills: string[]
  recruitmentSkills: string[]
}

export const SkillsChecklist: React.FC<SkillsChecklistProps> = ({
  applicationSkills,
  recruitmentSkills,
}) => {
  const fulfilledSkillCount = applicationSkills?.length ?? 0
  const requestedSkillCount = recruitmentSkills?.length ?? 0

  const { description, title } = useContent().manageCandidate.skills
  const score =
    Math.round((fulfilledSkillCount / requestedSkillCount) * 100) + '%'

  return (
    <Box>
      <Stack direction="row" alignItems="baseline">
        <ApplicantListIcon score={parseInt(score)} />
        <Typography variant="h6" ml={2} mb={3}>
          {title}
        </Typography>
      </Stack>

      <Typography variant="body1">
        {replaceEpiVariables(description, {
          candidateSkillsCount: fulfilledSkillCount.toString(),
          recruitmentSkillsCount: requestedSkillCount.toString(),
        })}
      </Typography>
      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        rowSpacing={{ xs: 0, md: 3 }}
        columnSpacing={7}
        overflow={'hidden'}
      >
        {fulfilledSkillCount > 0 && (
          <Grid item xs={1}>
            <List>
              {applicationSkills.map((skill, index) => (
                <ListItem key={index}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary={skill} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={1}>
          <List>
            {recruitmentSkills
              .filter(
                (recruitmentSkill) =>
                  applicationSkills?.indexOf(recruitmentSkill) === -1
              )
              .map((skill, index) => (
                <ListItem key={index}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <DoNotDisturbOnOutlined color="disabled" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: 'text.secondary' }}
                    primary={skill}
                  />
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}
