import { useFeatureFlag } from '@local/src/Utils/Hooks/useFeatureFlag'

declare global {
  interface Window {
    hj: (type: string, data: string) => void
  }
}

export const useHotjar = () => {
  const { isHotjarEnabled } = useFeatureFlag()

  return (eventName: string) => {
    if (!isHotjarEnabled) {
      console.warn('Hotjar feature is not enabled')
    } else if (typeof window?.hj !== 'function') {
      console.error('Hotjar is not a function: ', typeof window?.hj)
    } else {
      window.hj('event', eventName)
    }
  }
}
