import { useEffect, useState } from 'react'

export const useIsInView = (
  ref: React.MutableRefObject<HTMLElement | null>,
  options: IntersectionObserverInit
) => {
  const [isInView, setIsInView] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(
        (entry as IntersectionObserverEntry & { isVisible: boolean })
          .isVisible || entry.isIntersecting
      )
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref, options])

  return isInView
}
