import TextEditorToolbar from '@local/src/Components/TextEditor/TextEditorToolbar/TextEditorToolbar'
import { Box, Divider, FormHelperText } from '@mui/material'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'
import TextEditorWrapper from '@local/src/Components/TextEditor/TextEditorWrapper/TextEditorWrapper'
import Placeholder from '@tiptap/extension-placeholder'
import { FormattedText } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export interface TextEditorProps {
  content: string
  onChange: (value: FormattedText) => void
  editorId?: string
  placeholder?: string
  error?: boolean
  helperText?: string
  onBlur?: () => void
}

const TextEditor: React.FC<TextEditorProps> = ({
  content,
  onChange,
  placeholder,
  editorId,
  error,
  helperText,
  onBlur,
}) => {
  const editor = useEditor({
    extensions: [StarterKit, Placeholder.configure({ placeholder })],
    content,
    onUpdate: ({ editor }) => {
      onChange({
        html: editor.getHTML(),
        text: editor.getText(),
      })
    },
  })

  const handlePaperClick = () => {
    editor?.chain().focus().run()
  }

  return (
    <>
      <TextEditorWrapper
        variant="outlined"
        onClick={handlePaperClick}
        className={error ? 'error' : undefined}
        onBlur={onBlur}
      >
        <TextEditorToolbar editor={editor} p={1} />
        <Divider />
        <Box p={2} pt={0}>
          <EditorContent content={content} editor={editor} id={editorId} />
        </Box>
      </TextEditorWrapper>
      <FormHelperText sx={{ mx: 2 }} error={error}>
        {helperText}
      </FormHelperText>
    </>
  )
}

export default TextEditor
