import React, { useContext } from 'react'
import { Button, Stack, styled } from '@mui/material'
import { WizardContext } from '@local/src/Components/Wizard/Hooks/WizardContext'

interface ActionButtonProps {
  nextStep: () => void
  prevStep: () => void
}

interface WizardActionsProps {
  onPrimaryButtonClicked: (args: ActionButtonProps) => void
  onSecondaryButtonClicked?: (args: ActionButtonProps) => void
  primaryButtonText: string
  secondaryButtonText?: string
  disableGutterTop?: boolean
}

const StyledStack = styled(Stack, {
  shouldForwardProp: (propName) => propName !== 'disableGutterTop',
})<Pick<WizardActionsProps, 'disableGutterTop'>>(
  ({ theme, disableGutterTop = false }) => ({
    '&&': {
      marginTop: disableGutterTop ? undefined : theme.spacing(5),
    },
  })
)

export const WizardActions: React.FC<WizardActionsProps> = ({
  onPrimaryButtonClicked,
  onSecondaryButtonClicked,
  primaryButtonText,
  secondaryButtonText,
  disableGutterTop,
}) => {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('WizardActions must be used within a Wizard')
  }

  const { nextStep, prevStep } = context

  const handlePrimaryButtonClicked = () => {
    onPrimaryButtonClicked({ nextStep, prevStep })
  }

  const handleSecondaryButtonClicked = () => {
    onSecondaryButtonClicked({ nextStep, prevStep })
  }

  return (
    <StyledStack
      spacing={2}
      direction="row"
      disableGutterTop={disableGutterTop}
    >
      {onSecondaryButtonClicked && secondaryButtonText && (
        <Button variant="outlined" onClick={handleSecondaryButtonClicked}>
          {secondaryButtonText}
        </Button>
      )}
      <Button variant="contained" onClick={handlePrimaryButtonClicked}>
        {primaryButtonText}
      </Button>
    </StyledStack>
  )
}
