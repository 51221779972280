import React from 'react'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import { Button } from '@mui/material'
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentResponse,
} from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CandidateHistoryLog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/CandidateHistoryLog'
import { Motivation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/Motivation'
import { SkillsChecklist } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/SkillsChecklist'
import { ChangeApplicantStatusDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/ChangeApplicantStatusDialog/ChangeApplicantStatusDialog'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useSnackbar } from '@local/src/Components/InfoSnackbar/Hooks/useSnackbar'
import { StackDivider } from '@local/src/Components/StackDivider'
import { ContactInformation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/ContactInformation'
import { ButtonStack } from '@local/src/Components/ButtonStack/ButtonStack'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'

interface AppliedCandidateProps {
  application: RecruitmentApplication
  recruitment: RecruitmentResponse
  onClose: () => void
}

export const AppliedCandidate: React.FC<AppliedCandidateProps> = ({
  application,
  recruitment,
  onClose,
}) => {
  const [setStatus, { isLoading: isSetStatusLoading }] = useSetStatusMutation()
  const { showSnackbar } = useSnackbar()
  const { buttonPrimary, buttonSecondary, rejectedSnackbarMessage } =
    useContent().manageCandidate.actionMoveToOngoing

  const {
    isPending: isPendingConfirmation,
    resolve: resolveConfirmation,
    promise: confirm,
  } = useResolve<boolean>()

  const handleSetStatusRejected = async () => {
    await setStatus({
      applicationId: application.id,
      status: ApplicationStatus.Rejected,
    })
      .then(() => {
        showSnackbar(
          replaceEpiVariables(rejectedSnackbarMessage, {
            name: application?.person?.name ?? 'Användaren',
          }),
          6000
        )
        onClose()
      })
      .catch((error) => console.error(error))
  }

  const handleShowDialogClick = async () => {
    const confirmed = await confirm()

    return confirmed ? onClose() : null
  }

  return (
    <StackDivider data-testid="applied-candidate-wrapper">
      <CandidateHistoryLog historyLog={application?.history} />

      <Motivation text={application?.motivation} />

      <SkillsChecklist
        applicationSkills={application?.skills}
        recruitmentSkills={recruitment.position?.skills.map((x) => x.name)}
      />

      {(application?.person.phone || application?.person.email) && (
        <ContactInformation person={application.person} />
      )}

      <ButtonStack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => void handleShowDialogClick()}
          disabled={isSetStatusLoading}
        >
          {buttonPrimary}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => void handleSetStatusRejected()}
          disabled={isSetStatusLoading}
        >
          {buttonSecondary}
        </Button>
      </ButtonStack>
      <ChangeApplicantStatusDialog
        applicantName={application?.person?.name}
        applicationId={application.id}
        isOpen={isPendingConfirmation}
        onConfirm={() => resolveConfirmation(true)}
        onCancel={() => resolveConfirmation(false)}
      />
    </StackDivider>
  )
}
