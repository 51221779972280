import { useGetRecruitmentApplicationsQuery } from '@local/src/Api/applicationsApi'
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentApplicationsOrder,
} from '@local/src/Api/types'
import {
  ApplicantCard,
  ApplicantCardProps,
} from '@local/src/Components/ApplicantCard/ApplicantCard'
import { ApplicantCardSkeleton } from '@local/src/Components/ApplicantCard/ApplicantCardSkeleton'
import { useIsInView } from '@local/src/Utils/Hooks/useIsInView'
import { Box, Grid, GridProps } from '@mui/material'
import React, { useRef } from 'react'

type MapApplicationToApplicantCardProps = (
  application: RecruitmentApplication
) => ApplicantCardProps

interface PartialApplicantsListProps {
  recruitmentId: string
  page: number
  pageSize: number
  statuses: ApplicationStatus[]
  mapApplicationToCard: MapApplicationToApplicantCardProps
  orderBy?: RecruitmentApplicationsOrder
  count?: number
  containerProps: GridProps
}

export const PartialApplicantsList: React.FC<PartialApplicantsListProps> = ({
  recruitmentId,
  page,
  pageSize,
  statuses,
  orderBy,
  mapApplicationToCard,
  containerProps,
}) => {
  const ref = useRef<HTMLElement>(null)
  const isInView = useIsInView(ref, {
    rootMargin: '100px',
  })

  const { data } = useGetRecruitmentApplicationsQuery(
    {
      recruitmentId,
      orderBy,
      statuses,
      page,
      pageSize,
    },
    {
      skip: !isInView,
    }
  )

  const itemsCount = data?.applications.length ?? pageSize

  return (
    <Grid container {...containerProps} ref={ref} component={Box}>
      {Array.from({ length: itemsCount }).map((_, index) => {
        const application = data?.applications[index]
        return application ? (
          <Grid item xs={1} key={application.id}>
            <ApplicantCard {...mapApplicationToCard(application)} />
          </Grid>
        ) : (
          <Grid item xs={1} key={index}>
            <ApplicantCardSkeleton />
          </Grid>
        )
      })}
    </Grid>
  )
}
