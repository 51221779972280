import React, { PropsWithChildren } from 'react'
import { Grid, GridProps } from '@mui/material'

interface ResponsiveWrapperProps extends GridProps, PropsWithChildren {
  containterProps?: GridProps
}

export const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({
  children,
  containterProps,
  ...itemProps
}) => (
  <Grid container spacing={1} {...containterProps}>
    <Grid item {...itemProps}>
      {children}
    </Grid>
  </Grid>
)
