import React from 'react'
import {
  GetJobTitlesResponse,
  useGetJobTitlesQuery,
} from '@local/src/Api/jobTitlesApi'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  TextField,
} from '@mui/material'
import parse from 'html-react-parser'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Search } from '@mui/icons-material'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { BottomPopper } from '@local/src/Components/BottomPopper'
import { capitalizeFirstLetter } from '@local/src/Utils/stringHelpers'
import { RecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export const JobTitleField: React.FC = () => {
  const { label, placeholder, description } = useContent().form.jobTitle

  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()
  const [showOptions, setShowOptions] = React.useState(false)

  const shouldSearch = values.jobTitle?.name?.length > 2
  const { data: jobTitles } = useGetJobTitlesQuery(values.jobTitle?.name, {
    skip: !shouldSearch,
  })

  const handleInputChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setShowOptions(reason !== 'clear')
    reason === 'input' &&
      handleChange('jobTitle')({ name: value ?? '', id: undefined })
  }

  const handleAutocompleteChange = (
    _event: unknown,
    newValue: GetJobTitlesResponse,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'blur') return

    handleChange('jobTitle')({
      name: newValue?.jobTitle ?? '',
      id: newValue?.id,
    })
  }

  const handleOnClose = () => {
    setShowOptions(false)
  }

  const handleOptionLabel = (option: GetJobTitlesResponse | string) =>
    typeof option === 'string' ? option : option?.jobTitle

  const uniqueJobtitles = jobTitles
    ?.slice(0, 100)
    .reduce(
      (acc, current) =>
        acc.find((item) => item.jobTitle === current.jobTitle)
          ? acc
          : acc.concat([current]),
      [] as GetJobTitlesResponse[]
    )

  const mapValueToOption = (
    value: RecruitmentForm['jobTitle']
  ): GetJobTitlesResponse =>
    value
      ? {
          id: value.id ?? undefined,
          jobTitle: value.name ?? '',
          jobTitleWithHighLightedSearchTerm: value.name,
        }
      : undefined

  const options = shouldSearch ? uniqueJobtitles ?? [] : []

  return (
    <LabelWrapper description={description}>
      <ResponsiveWrapper xs={12} sm={8}>
        <Autocomplete
          popupIcon={<Search />}
          forcePopupIcon
          freeSolo
          autoHighlight
          autoSelect
          inputValue={values.jobTitle?.name}
          value={mapValueToOption(values.jobTitle)}
          onChange={handleAutocompleteChange}
          onInputChange={handleInputChange}
          options={showOptions && options ? options : []}
          onClose={handleOnClose}
          PopperComponent={BottomPopper}
          filterOptions={(options, params) =>
            [
              params.inputValue?.trim() &&
                !options.some(
                  (opt) =>
                    handleOptionLabel(opt).toLocaleLowerCase() ===
                    params.inputValue.trim().toLocaleLowerCase()
                ) && {
                  id: undefined,
                  jobTitle: capitalizeFirstLetter(params.inputValue),
                  jobTitleWithHighLightedSearchTerm: capitalizeFirstLetter(
                    params.inputValue
                  ),
                },
              ...options,
            ].filter(Boolean)
          }
          renderOption={(props, option) => (
            <li {...props} translate="no">
              {typeof option === 'string'
                ? option
                : parse(option.jobTitleWithHighLightedSearchTerm)}
            </li>
          )}
          getOptionLabel={handleOptionLabel}
          onBlur={handleBlur('jobTitle')}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              helperText={errors?.jobTitle?.name}
              error={Boolean(errors?.jobTitle?.name)}
            />
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
          }}
        />
      </ResponsiveWrapper>
    </LabelWrapper>
  )
}
