import {
  CompanyType,
  Skill,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export interface JobTitle {
  id?: string
  name: string
}

export enum PublishingStatus {
  Draft = 'Draft',
  // Published = 'Published',
  // Closed = 'Closed',
}

export enum RecruitmentStatus {
  Draft = 'Draft',
  Ongoing = 'Ongoing',
  Closed = 'Closed',
}

export enum EmploymentType {
  Employee = 'Employee',
  Hourly = 'Hourly',
  Seasonal = 'Seasonal',
  Other = 'Other',
}

export enum WorkingHours {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export interface GetAllRecruitmentsRequest {
  page: number
  pageSize: number
  status?: RecruitmentStatus
}

export enum ClosureReason {
  NotFilled = 'NotFilled',
  CandidateHired = 'CandidateHired',
  AnotherCandidateHired = 'AnotherCandidateHired',
}

export interface RecruitmentResponse {
  id: string
  status: RecruitmentStatus
  position: {
    heading: string
    jobTitle: JobTitle
    description: string
    descriptionFormatted: string
    locations: {
      id: string
      name: string
      locationType: string
    }[]
    skills: {
      name: string
    }[]
    workingHours: WorkingHours[]
    employmentType: EmploymentType
  }
  recruitingForCompany?: {
    name: string
    showName: boolean
  }
  metadata: {
    lastModifiedWhen: string
    createdWhen: string
  }
  company: {
    name: string
    description: string
    descriptionFormatted: string
    type: CompanyType
  }
  publication: {
    startDate?: string
    endDate?: string
    status: PublishingStatus
  }
  closure?: {
    date: string
    reason: ClosureReason
  }
}

export interface RecruitmentsWithApplicationStatusCategories
  extends RecruitmentResponse {
  applicationStatusCategories: ApplicationStatusCategories
}

interface ApplicationStatusCategories {
  interested: number
  ongoing: number
  notRelevant: number
  hired: number
}

export interface GetAllRecruitmentsResponse {
  totalHits: number
  totalPages: number
  recruitments: RecruitmentsWithApplicationStatusCategories[]
}

export enum PatchRecruitmentPaths {
  heading = 'ad/position/heading',
  jobtitle = 'ad/position/jobtitle',
  employmentDescriptionFormatted = 'ad/position/descriptionFormatted',
  employmentDescription = 'ad/position/description',
  location = 'ad/position/locations',
  skills = 'ad/position/skills',
  recruitingForCompany = 'ad/recruitingForCompany',
  companyName = 'ad/company/name',
  companyType = 'ad/company/type',
  companyDescription = 'ad/company/description',
  companyDescriptionFormatted = 'ad/company/descriptionFormatted',
  publicationEndDate = 'ad/publication/endDate',
  employmentType = 'ad/position/employmentType',
  workingHours = 'ad/position/workingHours',
}

export enum PathchOps {
  replace = 'replace',
}

export type PatchRecruitmentRequest<T, K extends PatchRecruitmentPaths> = {
  op: PathchOps
  path: K
  value: T
}

export type PatchApiRequests =
  | PatchRecruitmentRequest<string, PatchRecruitmentPaths.heading>
  | PatchRecruitmentRequest<JobTitle, PatchRecruitmentPaths.jobtitle>
  | PatchRecruitmentRequest<string, PatchRecruitmentPaths.employmentDescription>
  | PatchRecruitmentRequest<
      string,
      PatchRecruitmentPaths.employmentDescriptionFormatted
    >
  | PatchRecruitmentRequest<
      { id: string; name: string; locationType: string }[],
      PatchRecruitmentPaths.location
    >
  | PatchRecruitmentRequest<Skill[], PatchRecruitmentPaths.skills>
  | PatchRecruitmentRequest<
      { name?: string; showName?: boolean } | null,
      PatchRecruitmentPaths.recruitingForCompany
    >
  | PatchRecruitmentRequest<string, PatchRecruitmentPaths.companyName>
  | PatchRecruitmentRequest<string, PatchRecruitmentPaths.companyType>
  | PatchRecruitmentRequest<string, PatchRecruitmentPaths.companyDescription>
  | PatchRecruitmentRequest<
      string,
      PatchRecruitmentPaths.companyDescriptionFormatted
    >
  | PatchRecruitmentRequest<
      string | null,
      PatchRecruitmentPaths.publicationEndDate
    >
  | PatchRecruitmentRequest<
      EmploymentType,
      PatchRecruitmentPaths.employmentType
    >
  | PatchRecruitmentRequest<WorkingHours[], PatchRecruitmentPaths.workingHours>

export interface GetRecruitmentApplicationsResponse {
  applications: RecruitmentApplication[]
  totalPages: number
  totalHits: number
  page: number
}

export interface GetRecruitmentApplicationsRequest {
  recruitmentId: string
  orderBy?: RecruitmentApplicationsOrder
  statuses?: ApplicationStatus[]
  page?: number
  pageSize: number
}

export enum RecruitmentApplicationsOrder {
  BestMatch = 'BestMatch',
  LastModified = 'LastModified',
  CreatedWhen = 'CreatedWhen',
}

export enum ApplicationStatus {
  Applied = 'Applied',
  Rejected = 'Rejected',
  InProgress = 'InProgress',
  InterviewBooked = 'InterviewBooked',
  OfferPresented = 'OfferPresented',
  Hired = 'Hired',
  Withdrawn = 'Withdrawn',
}

export interface Person {
  id: string
  name: string
  phone: string
  email: string
}

export interface CandidateHistoryEvent {
  createdBy: Person
  createdWhen: string
  status: ApplicationStatus
}

export interface RecruitmentApplication {
  id: string
  createdWhen: string
  lastModifiedWhen: string
  status: ApplicationStatus
  person: Person
  skills: string[]
  motivation: string
  history: CandidateHistoryEvent[]
  isFavourite?: boolean
}
