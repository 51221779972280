import React, { useId } from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { WorkingHours } from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'

export const WorkingHoursField: React.FC = () => {
  const labelId = useId()

  const { values, handleChange, errors } = useRecruitmentForm()

  const handleChangeWorkingHours = (event: SelectChangeEvent<WorkingHours>) => {
    const value = event.target.value as WorkingHours
    handleChange('workingHours')(value)
  }

  const content = useContent().form.employmentInformation

  return (
    <FormControl fullWidth error={!!errors.workingHours}>
      <InputLabel id={labelId}>{content.workingHoursLabel}</InputLabel>
      <Select
        labelId={labelId}
        label={content.workingHoursLabel}
        value={values.workingHours}
        defaultValue=""
        onChange={handleChangeWorkingHours}
      >
        <MenuItem value={WorkingHours.FullTime}>
          {content.workingHoursOptionFullTime}
        </MenuItem>
        <MenuItem value={WorkingHours.PartTime}>
          {content.workingHoursOptionPartTime}
        </MenuItem>
      </Select>
      <FormHelperText>{errors.workingHours}</FormHelperText>
    </FormControl>
  )
}
