import React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { DetailsHeader } from '@local/src/Pages/DetailsPage/DetailsHeader/DetailsHeader'
import { PublicationDetails } from '@local/src/Pages/DetailsPage/PublicationDetails/PublicationDetails'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ClosureDetails } from '@local/src/Pages/DetailsPage/ClosureDetails/ClosureDetails'
import { RecruitmentStatus } from '@local/src/Api/types'
import { Applicants } from '@local/src/Pages/DetailsPage/Applicants/Applicants'
import { useCurrentRecruitment } from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { ClosedRecruitmentApplicants } from '@local/src/Pages/DetailsPage/Applicants/ClosedRecruitmentApplicants/ClosedRecruitmentApplicants'

export const DetailsPage: React.FC = () => {
  const { data: recruitment, isLoading, isError } = useCurrentRecruitment()

  const {
    errorMessage: { heading, description, startPagePath, actionLabel },
    settingsButton: {
      label: settingsButtonLabel,
      closedLabel: closedSettingsButtonLabel,
    },
  } = useContent().detailsPage

  if (isLoading) return <CircularProgress sx={{ m: 1, alignSelf: 'center' }} />

  if (isError || !recruitment)
    return (
      <EmptyResult
        heading={heading}
        description={description}
        action={{ link: startPagePath, label: actionLabel }}
      />
    )

  const { endDate, startDate } = recruitment.publication

  const isClosedRecruitment = recruitment.status === RecruitmentStatus.Closed

  return (
    <Stack spacing={3}>
      <DetailsHeader
        heading={recruitment.position.heading}
        buttonLabel={
          recruitment.status === RecruitmentStatus.Closed
            ? closedSettingsButtonLabel
            : settingsButtonLabel
        }
      />
      <Stack spacing={{ xs: 1, sm: 5 }} direction={{ xs: 'column', sm: 'row' }}>
        <PublicationDetails
          startDate={new Date(startDate)}
          endDate={endDate ? new Date(endDate) : undefined}
        />
        {isClosedRecruitment && (
          <ClosureDetails
            closureDate={
              recruitment.closure?.date
                ? new Date(recruitment.closure.date)
                : undefined
            }
            isClosed
          />
        )}
      </Stack>
      {isClosedRecruitment ? <ClosedRecruitmentApplicants /> : <Applicants />}
    </Stack>
  )
}
