import React from 'react'
import { useCloseRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { CloseRecruitmentForm } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/ClosureSettingsForm'
import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { Stack, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ClosureDetails } from '@local/src/Pages/DetailsPage/ClosureDetails/ClosureDetails'
import { ClosureReason } from '@local/src/Api/types'

interface ClosureSettingsProps {
  recruitmentId: string
  isClosed: boolean
  closureDate?: Date
}

export const ClosureSettings: React.FC<ClosureSettingsProps> = ({
  recruitmentId,
  isClosed,
  closureDate,
}) => {
  const {
    confirmationDialogTitle,
    confirmationDialogMessage,
    confirmationDialogConfirmLabel,
    confirmationDialogCancelLabel,
  } = useContent().detailsPage.closure

  const formikRef = React.useRef<FormikProps<CloseRecruitmentFormValues>>(null)

  const {
    isPending: isPendingConfirmation,
    promise: confirm,
    resolve: resolveConfirmation,
  } = useResolve<CloseRecruitmentFormValues | false>()

  const [closeRecruitment] = useCloseRecruitmentMutation()

  const handleCloseRecruitment = async () => {
    const result = await confirm()
    if (result) {
      void closeRecruitment({
        id: recruitmentId,
        reason:
          result.reason === ClosureReason.AnotherCandidateHired
            ? ClosureReason.CandidateHired
            : result.reason,
        applicationsToHire: result.applicationsToHire,
      })
    }
  }

  const handleConfirmCloseRecruitment = async () => {
    const errors = await formikRef.current?.validateForm()
    if (errors && Object.keys(errors).length === 0) {
      resolveConfirmation(formikRef.current?.values)
    }
  }

  return (
    <>
      <ClosureDetails
        isClosed={isClosed}
        closureDate={closureDate}
        onCloseClick={() => void handleCloseRecruitment()}
      />
      <ConfirmationDialog
        isOpen={isPendingConfirmation}
        title={confirmationDialogTitle}
        confirmLabel={confirmationDialogConfirmLabel}
        cancelLabel={confirmationDialogCancelLabel}
        onConfirm={() => void handleConfirmCloseRecruitment()}
        onClose={() => resolveConfirmation(false)}
      >
        <Stack spacing={3}>
          <Typography>{confirmationDialogMessage}</Typography>
          <CloseRecruitmentForm ref={formikRef} recruitmentId={recruitmentId} />
        </Stack>
      </ConfirmationDialog>
    </>
  )
}
