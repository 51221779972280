import React, { PropsWithChildren } from 'react'
import { Close } from '@mui/icons-material'
import { IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material'
import { LoadingIndicator } from '@local/src/Components/LoadingIndicator/LoadingIndicator'
import { StackDivider } from '@local/src/Components/StackDivider'

interface DrawerProps extends PropsWithChildren {
  title: string
  subtitle?: React.ReactNode
  isLoading?: boolean
  isError?: boolean
  isOpen: boolean
  onClose: () => void
}

export const Drawer: React.FC<DrawerProps> = ({
  title,
  isLoading,
  isError,
  isOpen,
  onClose,
  children,
  subtitle,
}) => {
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={onClose}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '100vw',
          p: { xs: 2, sm: 3, md: 5 },
        },
      }}
    >
      <Stack position="relative" data-testid="drawer-wrapper" spacing={3}>
        {isLoading && (
          <LoadingIndicator alignSelf="center" position="absolute" top="50vw" />
        )}
        {isError && <Typography>{'Något gick fel!'}</Typography>}
        {!isLoading && !isError && (
          <StackDivider>
            <Stack spacing={1}>
              <Typography variant="h4" pt={2}>
                {title}
              </Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
                data-testid="drawer-close-button"
              >
                <Close />
              </IconButton>
              {subtitle}
            </Stack>
            {children}
          </StackDivider>
        )}
      </Stack>
    </SwipeableDrawer>
  )
}
